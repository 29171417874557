import { IEffortArea } from '../effort-area';
import { Model } from '@app-ngrx-domains';
import { Utilities} from '../utilities';
import { RolePermissions } from '../role-permission';

export interface IEAContactSection extends IEffortArea {
  order: number;
  contact_types: Array<Model.EAContactType>
}

export interface IEAContactType extends IEffortArea {
  role: RolePermissions;
  contacts?: Array<Model.UserRoleScope>;
  approver: boolean;
  required: number;
  readOnly: boolean;
  multi: boolean;
  designee: boolean;
  specialType: string;
  institution: string;
  institutions?: Array<Model.SelectOption>;
}

export interface IEAProgramSettings extends IEffortArea {
  fund_id: number;
  participating_institution_types: Array<Model.AttributeValue>;
  participating_institution_ids: Array<Model.AttributeValue & { institution: Model.Institution }>;
  partner_institution_types: Array<Model.AttributeValue>;
  grant_id: string;
  agreement_number?: string;
  fiscal_agent_id?: number;
  fiscal_agent_settings: Model.EAFiscalAgentSettings;
  base_duration_id: number;
  plan_length: number;
  assurances: Array<IEAAssurance>;
  settings_by_year: Array<Model.EASettingsByYear>;
  settings_pillars: Array<Model.EASettingsPillars>;
  reporting_periods: Array<IEAReportingPeriod>;
  budget_match_requirements: Array<IEABudgetMatchRequirement>,
  guidances: Array<IEAGuidance>,
  objectives: Array<Model.EAObjective>,
  institution_label: string;
  renewal_limit: number;
  contact_sections: Array<Model.EAContactSection>;
  contact_table_types: Array<Model.EAContactTableTypes>;
  core_indicator_targets?: IEACoreIndicatorTargets;
  collect_sector_roles?: boolean,
  name?: string; // Added in programService
  budget_limit?: number;
  grant_sector_ids?: Array<Model.AttributeValue>;
  is_rfa: boolean;
  rc_contributor_settings: Array<IEARCContributorSettings>,
  rc_contributor_snapshots: Array<IEARCContributorSnapshots>,
  rfa_settings: IEARfaSettings;
  is_private?: boolean;
  include_expense_reporting?: boolean;
  include_final_report?: boolean;
  fiscal_reporting_cadence?: string;
  reporting_approval_cadence?: string;
  fiscal_reporting_guidance?: string;
  supporting_document_types?: Array<IEASupportingDocumentTypes>;
  program_division_id?: number;
  has_match_requirements?: boolean;
  approver_settings: Model.EAApproverSettings;
  notification_settings: Model.EANotificationSettings;
  workflow_steps?: Array<IEAWorkflowSteps>;
  data_link?: string;
  files?: Array<Model.Document>;
  calendar_color?: string;
  funding_year?: number;
  start_quarter?: number;
  quarterly_plan_length?: number;
  enable_encumbered_funds?: boolean;
  reporting_extensions: Array<Model.EAReportingExtensions>;
  caep_carryover_thresholds?: Array<Model.EACarryoverThresholds>;
  open_for_all?: boolean;
  report_certification_institution_ids?: Array<Model.AttributeValue & { institution: Model.Institution }>;
  aggregate_funding_sources?: boolean;
  ignore_contributor_settings?: boolean;
  use_funding_categories?: boolean;
  require_related_activities?: boolean;
  budget_modification_limit?: number;
  enable_carryover_management?: boolean;
}

export interface IEAFiscalAgentSettings extends IEffortArea {
  plan_length: number;
  reporting_periods: Array<Model.EAReportingPeriod>;
}

export interface IEAAssurance extends IEffortArea {
  files: Array<Model.Document>;
}

export interface IEABudgetMatchRequirement extends IEffortArea {
  institution_type: string;
  match_percent?: number;
  in_kind_match_percent?: number;
}

export interface IEAGrantSector extends IEffortArea {
  grant_display_id: string;
  sector_id: number;
}

export interface IEAGuidance extends IEffortArea {
  proposal_type: string;
  workflow_step: string;
  workflow_name?: string;
  field_name?: string;
  order?: number;
}

export interface IEAReportingPeriod extends IEffortArea {
  forecast_quarter: number;
  spending_benchmark_percentage: number;
  submit_assurance: string;
  approval_assurance: string;
  require_certification: number;
  lock_on_approval: boolean;
  extension_year: number;
  survey_template_id: number;
  show_on_last: boolean;
  is_final_report: boolean;
}

export interface IEAContactTableTypes extends IEffortArea {
  role_id: number;
  number_required?: number;
  prevent_delete_last?: boolean;
  sort_order?: number;
  notify_on_assignment?: boolean;
}

export interface IEAReportingPeriodExtension extends IEffortArea {
  reporting_period_extension: number;
}

export interface IGuidanceWorkflowFilter {
  canEdit?: boolean,
  isTemplate?: boolean,
  isPreview?: boolean,
  programId: number,
  proposalType: string,
  workflowName: string,
  stepName: string,
  stepTitle?: string
}

export interface IGuidanceTreeItem {
  label: string,
  workflowLabel?: string,
  link?: string,
  queryParams?: {workflow: string, step: string},
  status?: string
}

export interface IEACoreIndicatorTargets extends IEffortArea {
  core_indicator_1p1: number;
  core_indicator_2p1: number;
  core_indicator_3p1: number;
  splt_1p1: number;
  splt_2p1: number;
  splt_3p1: number;
}

export interface IEARCContributorSettings extends IEffortArea {
  fund_name?: string;
  plan_length: number;
  reporting_periods: Array<IEAReportingPeriod>;
  match_percent: number;
  funding_year?: number;
  start_year?: number;
  start_quarter?: number;
  allocation_amount?: number;
  include_expense_reporting?: boolean;
  enable_encumbered_funds?: boolean;
  fiscal_reporting_cadence?: string;
  reporting_approval_cadence?: string;
  quarterly_plan_length?: number;
  budget_limit?: number;
  enable_carryover_management?: boolean;
  carryover_of?: number;
}

export interface IEARCContributorSnapshots extends IEffortArea {
  plan_length: number;
  allocation_amount: number;
  fiscal_reporting_cadence: string;
  match_percent: number;
}

export interface IEARfaSettings extends IEffortArea {
  batch_offer_letters: boolean;
  enable_outlier: boolean;
  enable_tie_break: boolean;
  minimum_funding_score: number;
  minimum_reviewers: number;
  outlier_deviation: number;
  scoring_rubric_url: string;
  total_points: number;
}

export interface IEASupportingDocumentTypes extends IEffortArea {
  document_type: Model.DocumentType;
  required: boolean;
}

export interface IEAApproverSettings extends IEffortArea {
  plan_approvers: Array<Model.AttributeValue>;
  fiscal_approvers: Array<Model.AttributeValue>;
}

export interface IEANotificationSettings extends IEffortArea {
  on_app_submit: Array<Model.AttributeValue>;
  on_funding: Array<Model.AttributeValue>;
  on_submit: Array<Model.AttributeValue>;
  on_unsubmit: Array<Model.AttributeValue>;
  on_certify: Array<Model.AttributeValue>;
  on_fund_transfer: Array<Model.AttributeValue>;
}

export interface IEAWorkflowSteps extends IEffortArea {
  workflow_name: string;
  survey_id: number;
  sort_order: number;
  enabled: boolean;
  template?: Model.EASurveyTemplate;
  max_score?: number;
  score_required?: boolean;
  scoring_instruction?: string;
}

export interface IEAReportingExtensions extends IEffortArea {
  proposal_id: Model.ProposalItem;
  member_ids: Array<Model.AttributeValue>;
  due_date: string;
}

/**
 * Program Settings related static helper functions.
 */
export class ProgramSettings {

  /**
   * Looks for matching guidance.
   * @param guidances
   * @param workflowFilter
   * @param fieldName
   */
  static findGuidance(guidances: Array<Model.EAGuidance> = [], workflowFilter: Model.GuidanceWorkflowFilter, fieldName: string): Model.EAGuidance {
    const result = guidances.find(g =>
      g.proposal_type === workflowFilter.proposalType
      && g.workflow_name === workflowFilter.workflowName
      && g.workflow_step === workflowFilter.stepName
      && g.field_name === fieldName);
    return result;
  }

  /**
   * Returns guidance text that matches the workflow filter & the field name.
   * @param guidances
   * @param workflowFilter
   * @param fieldName
   */
  static getGuidanceText(guidances: Array<Model.EAGuidance>, workflowFilter: Model.GuidanceWorkflowFilter, fieldName: string): string {
    const guidance = this.findGuidance(guidances, workflowFilter, fieldName);
    if (guidance) {
      return guidance.description;
    }
  }


  static getGuidanceLastEditedDate(guidances: Array<Model.EAGuidance>, workflowFilter: Model.GuidanceWorkflowFilter): string {
    const list = (guidances || []).filter(g =>
      g.proposal_type === workflowFilter.proposalType
      && g.workflow_name === workflowFilter.workflowName
      && g.workflow_step === workflowFilter.stepName);

    if (!!list.length) {
      const lastEdited = list.reduce((latest, guidance) => latest < guidance.updated_at ? guidance.updated_at : latest, '');
      return Utilities.convertToNOVADateTime(lastEdited, 'MMM DD, YYYY, hh:mm A');
    }
  }
}

export const PROGRAM_SETTINGS_RANGES = {
  rfa_settings: {
    minimum_reviewers: {min: 1, max: 30},
    minimum_funding_score: {min: 0, max: 100},
    outlier_deviation: {min: 10, max: 30},
  }
};

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAProgramSettings = IEAProgramSettings;
    export type EAFiscalAgentSettings = IEAFiscalAgentSettings;
    export type EAAssurance = IEAAssurance;
    export type EABudgetMatchRequirement = IEABudgetMatchRequirement;
    export type EAGrantSector = IEAGrantSector;
    export type EAGuidance = IEAGuidance;
    export type EAReportingPeriod = IEAReportingPeriod;
    export type EAReportingPeriodExtension = IEAReportingPeriodExtension;
    export type EARfaSettings = IEARfaSettings;
    export type GuidanceWorkflowFilter = IGuidanceWorkflowFilter;
    export type GuidanceTreeItem = IGuidanceTreeItem;

    export type EAContactType = IEAContactType;
    export type EAContactSection = IEAContactSection
    export type EAContactTableTypes = IEAContactTableTypes;
    export type EACoreIndicatorTargets = IEACoreIndicatorTargets;
    export type EARCContributorSnapshots = IEARCContributorSnapshots;
    export type EARCContributorSettings = IEARCContributorSettings;
    export type EASupportingDocumentTypes = IEASupportingDocumentTypes;
    export type EAApproverSettings = IEAApproverSettings;
    export type EANotificationSettings = IEANotificationSettings;
    export type EAWorkflowSteps = IEAWorkflowSteps;
    export type EAReportingExtensions = IEAReportingExtensions;
  }
}
