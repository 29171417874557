import { YEARS } from './durations.const';

/**
 * Use this module to list out commonly used constants through out the application.
 */

export const WELCOME_MESSAGE = 'Welcome to NOVA';

export const ACTION_TASKMAN = 123456789;

export const BUDGET_TYPES = {
  BSI: 'bsi',
  SE: 'se',
  SSSP_CREDIT: 'sssp-credit',
  SSSP_CREDIT_MATCH: 'sssp-credit-match',
  SSSP_NONCREDIT: 'sssp-non-credit',
  SSSP_NONCREDIT_MATCH: 'sssp-non-credit-match',
  AEBG_CONSORTIUM: 'aebg-consortium',
};

export const PROGRAM_KEYS = {
  // parent program keys
  SWP_R: 'swpr',
  SWP_L: 'swpl',
  CAEP: 'caep',
  IPLAN: 'iplan',
  SSSP: 'iplan',
  SE: 'iplan',
  BSI: 'iplan',
  GP: 'gp',
  GP_1: 'gp-v1',
  GP_2: 'gp-v2',
  SWP_K12: 'swpk',
  LVG: 'lvg',
  SEP: 'sep',
  EWD: 'ewd',
  NEP: 'nep',
  CAI: 'cai',
  PERKINS: 'perkins',
  RCM: 'rcm',

  // child program keys
  RFA: 'rfa',
  RFA_v2: 'rfa-v2',
  RSI: 'rsi',
  SWPR_v1: 'swpr-v1',
  SWPR_v2: 'swpr-v2',
  SWPR_v3: 'swpr-v3',
  SWPL_v1: 'swpl-v1',
  SWPL_v2: 'swpl-v2',
  SWPL_v3: 'swpl-v3',
  SWP_K12_v1: 'swpk-v1',
  SWP_K12_v2: 'swpk-v2',
  SWP_K12_v4: 'swpk-v4',
  SWP_K12_v5: 'swpk-v5',
  SWP_K12_TAP: 'swpk-tap',
  SWP_K12_PC: 'swpk-pc',
  PERKINS_1C: 'perkins-1c',
  PERKINS_1B: 'perkins-1b',
  PERKINS_RESERVE: 'perkins-reserve',
  PERKINS_NTCT: 'perkins-ntct',
  GRANTS: 'grant',
  EWD_RD: 'ewd-rd',
  EWD_SD: 'ewd-sd',
  EWD_COE: 'ewd-coe',
  EWD_TAP: 'ewd-tap',
  RCM_C: 'rcm-c',
  SEP_V2: 'sep-v2',
  ALLIED_HEALTH: 'ah',

  // Fiscal Agent - Not an actual program_key, since they belong to an RFA fund
  FISCAL_AGENTS: 'fiscal-agents',

  SMALL_PROGRAMS: 'sp',
}

export const SUCCESS_GOAL_GROUPS = {
  DEFAULT: 'default',
  CAEP: PROGRAM_KEYS.CAEP,
  PERKINS_1C: PROGRAM_KEYS.PERKINS_1C,
  VISION_2030: 'vision-2030'
};

export const METRIC_GROUPS = {
  DEFAULT: 'lb',
  CAI: PROGRAM_KEYS.CAI,
  CAI_V2: 'cai-v2',
  CAEP: PROGRAM_KEYS.CAEP,
  CAEP_PROGRAMS: `${PROGRAM_KEYS.CAEP}_programs`,
  EWD: PROGRAM_KEYS.EWD,
  GP: PROGRAM_KEYS.GP,
  LVG: PROGRAM_KEYS.LVG,
  SWP: 'swp',
  SWP_K12: PROGRAM_KEYS.SWP_K12,
  PERKINS: PROGRAM_KEYS.PERKINS,
  SEP: PROGRAM_KEYS.SEP,
};

export const METRIC_GROUPS_LABELS = {
  [METRIC_GROUPS.DEFAULT]: 'Student Success Metrics',
  [METRIC_GROUPS.SWP]: 'SWP Metrics'
};

export const FUND_TYPES = {
  ALL: 0, // Not a valid fund_id; used only to index non-fund-specific settings in this consts file.
  SWP_R: 1,
  SWP_L: 2,
  SWP_K12: 23,
  AEBG: 3,
  IPLAN: 4,
  SE: 5,
  BSI: 6,
  GP: 7,
  LVG: 24,
  SEP: 25,
  SSSP: 30,
  SSSP_C: 31,
  SSSP_M: 32,
  SSSP_CM: 33,
  // collection types
};

export const  FUND_SETTINGS: any = {
  [FUND_TYPES.SWP_R]: {
    currentYear: 2020,
  },
  [FUND_TYPES.SWP_L]: {
    currentYear: 2020,
  },
  [FUND_TYPES.AEBG]: {
    name: 'CAEP',
    longName: 'California Adult Education Program',
    headerName: 'CAEP',
    columnName: 'CAEP',
    programKey: PROGRAM_KEYS.CAEP,
    institution: 'consortium',
    institutionLabel: 'Consortium',
    collections: [FUND_TYPES.AEBG],
    budgetTypeMenuOptions: [],
    programYears: {},
    currentYear: 2023,
    allocationsBaseYear: 2017,
  },
  [FUND_TYPES.SSSP]: {
    name: 'Integrated Planning - SSSP',
    longName: 'Student Success and Support Program',
    headerName: 'SSSP',
    columnName: 'SSSP',
    programKey: PROGRAM_KEYS.IPLAN,
    institution: 'college',
    institutionLabel: 'College',
    collections: [],
    budgetTypeMenuOptions: [],
    programYears: {},
    currentYear: 2018,
    allocationsBaseYear: 2018,
  },
  [FUND_TYPES.SE]: {
    name: 'Integrated Planning - SE',
    longName: 'Student Equity Program',
    headerName: 'SE',
    columnName: 'SE',
    programKey: PROGRAM_KEYS.IPLAN,
    institution: 'college',
    institutionLabel: 'College',
    collections: [],
    budgetTypeMenuOptions: [],
    programYears: {},
    currentYear: 2018,
    allocationsBaseYear: 2018,
  },
  [FUND_TYPES.BSI]: {
    name: 'Integrated Planning - BSI',
    longName: 'Basic Skills Initiative',
    headerName: 'BSI',
    columnName: 'BSI',
    programKey: PROGRAM_KEYS.IPLAN,
    institution: 'college',
    institutionLabel: 'College',
    collections: [],
    budgetTypeMenuOptions: [],
    programYears: {},
    currentYear: 2018,
    allocationsBaseYear: 2018,
  },
  [FUND_TYPES.IPLAN]: {
    name: 'Integrated Planning',
    longName: 'Integrated Planning',
    headerName: 'Integrated Planning',
    columnName: 'IPlan',
    programKey: PROGRAM_KEYS.IPLAN,
    institution: 'college',
    institutionLabel: 'College',
    collections: [
      FUND_TYPES.SSSP,
      FUND_TYPES.SE,
      FUND_TYPES.BSI,
      FUND_TYPES.SSSP,
      FUND_TYPES.SSSP_C,
      FUND_TYPES.SSSP_M,
      FUND_TYPES.SSSP_CM
    ],
    budgetTypeMenuOptions: [
      { value: FUND_TYPES.BSI, label: 'Basic Skills Initiative' },
      { value: FUND_TYPES.SE, label: 'Student Equity' },
      { value: FUND_TYPES.SSSP, label: 'Noncredit SSSP' },
      { value: FUND_TYPES.SSSP_C, label: 'Credit SSSP' },
      { value: FUND_TYPES.SSSP_M, label: 'Noncredit SSSP Match' },
      { value: FUND_TYPES.SSSP_CM, label: 'Credit SSSP Match' },
    ],
    programYears: {},
    currentYear: 2018,
    allocationsBaseYear: 2018,
  },
  [FUND_TYPES.GP]: {
    name: 'Guided Pathways',
    longName: 'Guided Pathways',
    headerName: 'Guided Pathways',
    columnName: 'GP',
    programKey: PROGRAM_KEYS.GP,
    institution: 'college',
    institutionLabel: 'College',
    collections: [FUND_TYPES.GP],
    budgetTypeMenuOptions: [],
    programYears: {
      [YEARS.YEAR_2018_19]: { id: YEARS.YEAR_2018_19, name: `Spring 2018-Summer 2019`, longName: 'Spring 2018 - Summer 2019' },
      [YEARS.YEAR_2019_20]: { id: YEARS.YEAR_2019_20, name: `Fall 2019-Summer 2020`, longName: 'Fall 2019 - Summer 2020' },
      [YEARS.YEAR_2020_21]: { id: YEARS.YEAR_2020_21, name: `Fall 2020-Summer 2021`, longName: 'Fall 2020 - Summer 2021' },
      [YEARS.YEAR_2021_22]: { id: YEARS.YEAR_2021_22, name: `Fall 2021-Summer 2022`, longName: 'Fall 2021 - Summer 2022' },
    },
  },
  [FUND_TYPES.LVG]: {
    name: 'Local Vision Goals',
    longName: 'Local Vision Goals',
    headerName: 'Local Vision Goals',
    columnName: 'LVG',
    programKey: PROGRAM_KEYS.LVG,
    institution: 'college',
    institutionLabel: 'College',
    collections: [FUND_TYPES.LVG],
    budgetTypeMenuOptions: [],
    programYears: {},
    currentYear: 2017,
    allocationsBaseYear: 2017,
    metricsBaseYear: 2017,
  },
};
FUND_SETTINGS[FUND_TYPES.SSSP_C] = FUND_TYPES.SSSP;
FUND_SETTINGS[FUND_TYPES.SSSP_M] = FUND_TYPES.SSSP;
FUND_SETTINGS[FUND_TYPES.SSSP_CM] = FUND_TYPES.SSSP;



export const ENTITY_CHILD_ROUTES = {
  ANNUAL_PLAN: 'annual-plan',
  CFAD: 'cfad',
  CFAD_AMENDMENT: 'cfad-amendment',
  CONSORTIA: 'consortia',
  FISCAL_REPORTS: 'fiscal-reports',
  PREVIEW: 'preview',
  PROJECT: 'project',
  PROPOSALS: 'proposals',
  PROGRAM_AREA_REPORTS: 'program-area-reports',
  THREE_YEAR_PLAN: 'three-year-plan',
  WEP: 'written-expenditure-plan'
};

export const WORKPLAN_TYPES = {
  EFFORT1: 'effort1',
  EFFORT2: 'effort2',
  EFFORT3: 'effort3',
  EFFORT4: 'effort4',
  GOAL: 'goal',
  GP: 'gp_',
  GP_INQUIRY: 'gp_inquiry_',
  GP_INQUIRY_1: 'gp_inquiry_1',
  GP_INQUIRY_2: 'gp_inquiry_2',
  GP_INQUIRY_3: 'gp_inquiry_3',
  GP_DESIGN: 'gp_design_',
  GP_DESIGN_1: 'gp_design_1',
  GP_DESIGN_2: 'gp_design_2',
  GP_DESIGN_3: 'gp_design_3',
  GP_DESIGN_4: 'gp_design_4',
  GP_DESIGN_5: 'gp_design_5',
  GP_IMPLEMENTATION: 'gp_implementation_',
  GP_IMPLEMENTATION_1: 'gp_implementation_1',
  GP_IMPLEMENTATION_2: 'gp_implementation_2',
  GP_IMPLEMENTATION_3: 'gp_implementation_3',
  GP_IMPLEMENTATION_4: 'gp_implementation_4',
  GP_IMPLEMENTATION_5: 'gp_implementation_5',
  GP_IMPLEMENTATION_6: 'gp_implementation_6',
  GP_ASSESSMENT: '_assessment'
};

export const WORKPLAN_GOAL_AREAS = [
  { value: 1, label: 'Access' },
  { value: 2, label: 'Retention' },
  { value: 3, label: 'Transfer' },
  { value: 4, label: 'ESL / Basic Skills' },
  { value: 5, label: 'Degree & Certificate Completion' },
  { value: 6, label: 'Other' },
];

export const WORKPLAN_REQUIRED_NUMBER_GOALS = 5;

export const
ROUTER_LINKS = {
  PRODUCTION_BASE_URL: 'https://nova.cccco.edu',
  MAINTENANCE: '/maintenance',
  HOME: '/',
  ADMIN: '/admin',
  PROFILE: '/profile',
  LOGIN: '/login',
  INSTITUTIONS: '/institutions',
  UNSUPPORTED_BROWSER: '/unsupported-browser',
  PAGE_ACCESS_DENIED: '/page-access-denied',
  PAGE_NOT_FOUND: '/page-not-found',
  AEBG: '/caep',
  AEBG_CONSORTIA: '/caep/consortia',
  AEBG_MEMBERS: '/caep/members',
  FISCAL_AGENTS: '/fiscal-agents',
  GP: '/gp',
  IPLAN: '/iplan',
  LVG: '/lvg',
  LVG_GOALS: '/lvg/goals',
  SEP: '/sep',
  SEP_PLANS: '/sep/plans',
  SEP_ALLOCATIONS: '/sep/settings/allocations',
  SEP_REPORTING: '/sep/reporting',
  SWP_L: '/swpl',
  SWP_R: '/swpr',
  SWP_K12: '/swpk',
  SWP_K12_APPS: '/swpk/applications',
  SWP_K12_FUNDING: '/swpk/funding',
  SWP_K12_PLANS: '/swpk/plans',
  SWP_K12_REVIEWS: '/swpk/reviews',
  CAI: '/cai',
  CAI_APPS: '/cai/applications',
  CAI_FUNDING: '/cai/funding',
  CAI_REVIEWS: '/cai/reviews',
  CAI_PLANS: '/cai/plans',
  RSI_REPORTS: '/cai/rsi',
  EWD: '/ewd',
  EWD_APPS: '/ewd/applications',
  EWD_FUNDING: '/ewd/funding',
  EWD_REVIEWS: '/ewd/reviews',
  EWD_PLANS: '/ewd/plans',
  NEP: '/nep',
  NEP_PLANS: '/nep/plans',
  PERKINS_1C_HEADCOUNT: `perkins/${PROGRAM_KEYS.PERKINS_1C}/headcount`,
  PERKINS_1C_APPS: `perkins/${PROGRAM_KEYS.PERKINS_1C}/applications`,
  PERKINS_1B_APPS: `perkins/${PROGRAM_KEYS.PERKINS_1B}/applications`,
  PERKINS_1B_PLANS: `perkins/${PROGRAM_KEYS.PERKINS_1B}/plans`,
  PERKINS_RESERVE_APPS: `perkins/${PROGRAM_KEYS.PERKINS_RESERVE}/applications`,
  PERKINS_RESERVE_PLANS: `perkins/${PROGRAM_KEYS.PERKINS_RESERVE}/plans`,
  PERKINS_NTCT_APPS: `perkins/${PROGRAM_KEYS.PERKINS_NTCT}/applications`,
  PERKINS_NTCT_PLANS: `perkins/${PROGRAM_KEYS.PERKINS_NTCT}/plans`
};

export const CORE_ROUTES = {
  MAINTENANCE: 'maintenance',
  ADMIN: 'admin',
  INSTITUTIONS: 'institutions',
  SIGN_UP: 'sign_up',
  CONTACT_SENT: 'contactSent',
  FUNDS: 'funds',
  HELP: 'help',
  PRIVACY_POLICY: 'privacy-policy',
  REQUEST_ACCESS: 'request-access',
  LOGIN: 'login',
  NOTIFICATIONS: 'notifications',
  PAGE_ACCESS_DENIED: 'page-access-denied',
  PAGE_NOT_FOUND: 'page-not-found',
  UNSUPPORTED_BROWSER: 'unsupported-browser',
  FORGOT_PASSWORD: 'forgotPassword',
  PASSWORD_RESET: 'passwordReset',
  PROFILE: 'profile',
  WELCOME: 'welcome',
  REDIRECT: 'redirect',
  RELEASE_NOTES: 'releaseNotes',
  STYLEGUIDE: 'styleguide'
}

export const ACTION_BUTTONS = {
  BACK: 'back_button',
  DUPLICATE: 'duplicate',
  INFO_LINK: 'info_link_step_button',
  MODAL: 'modal',
  NEW: 'create_new_button',
  NEW_LEGACY: 'create_new_legacy_button',
  NEXT: 'next_step_button',
  PRINT: 'print_button',
  PRINT_TEMPLATE: 'print_template_step_button',
  THREE_YEAR_PLAN_PREVIEW: 'three_year_plan_preview_button',
};

export const TIMEOUT_DELAYS = {
  SHORT: 4000, // millisec
  MEDIUM: 8000, // millisec
  LONG: 16000, // millisec
  IDLE_ALERT: 55 * 60, // sec
  IDLE_TIMEOUT: 5 * 60, // sec
  AUTH_REFRESH: 300000, // 5 min in millisec
  MAINTENANCE_CHECK: 900000 // every 15 minutes
};

export const CHAR_LIMITS = {
  NARRATIVE_MIN: 2,
  NARRATIVE_MAX: 3500,
  NARRATIVE_MAX_SHORT: 3000,
  EXTRA_LONG: 10000,
  HAPPY_LONG: 8000,
  SHLONG: 7500,
  EXTRA_MEDIUM: 5000,
  HAPPY_MEDIUM: 2500,
  LONG: 1000,
  MEDIUM: 500,
  SHMEDIUM: 255,
  SHORT: 100,
  EXTRA_SHORT: 35,
  COMMENTS: 1000,
  ID_MIN: 3,
  ID_MAX: 20,
};

export const STATUS_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const OBJECT_CODE_TYPES = {
  NO_INDIRECT: 'no-indirect',
  K12_V2: PROGRAM_KEYS.SWP_K12_v2,
  DEFAULT: 'default'
};

export const OBJECT_CODES = {
  INDIRECT_COSTS: 1269,
  CAPITAL_OUTLAY: 1248,
};

export const METRIC_DEFINITIONS = {
  NUMBER_ENROLLMENTS: 1,
};

export const REC_VALUE_CHANGE = {
  INCREMENT: 1,
  DECREMENT: -1,
};

export const STATE_TYPES = {
  UNDEFINED: 0,
  DRAFT: 1,
  SUBMITTED: 2,
  REJECTED: 3,
  CERTIFIED: 4,
  COMMITTED: 5,
  CONTRACTED: 6,
  CREATED: 7,
  OWNER_SUBMITTED: 8,
  ALL_MEMBERS_APPROVED: 9,
  STATE_APPROVED: 10,
  ATTENTION_NEEDED: 11,
  DELETED: 12,
  CANCELED: 13,
  SAVED: 14,
  CLOSE_REQUESTED: 15,
  CLOSED: 16,
  EDITED: 17,
  EDIT_SUBMITTED: 18
};

export const STATES = {
  DRAFT: { id: 1, name: 'Draft' },
  SUBMITTED: { id: 2, name: 'Submitted' },
  REJECTED: { id: 3, name: 'Rejected' },
  CERTIFIED: { id: 4, name: 'Certified' },
  COMMITTED: { id: 5, name: 'Committed' },
  CONTRACTED: { id: 6, name: 'Contracted' },
  CREATED: { id: 7, name: 'Created' },
  OWNER_SUBMITTED: { id: 8, name: 'Owner Submitted' },
  ALL_MEMBERS_APPROVED: { id: 9, name: 'All Members Approved' },
  STATE_APPROVED: { id: 10, name: 'State Approved' },
  ATTENTION_NEEDED: { id: 11, name: 'Attention Needed' },
  DELETED: { id: 12, name: 'Deleted' },
  CANCELED: { id: 13, name: 'Canceled' },
  SAVED: { id: 14, name: 'Saved' },
  CLOSE_REQUESTED: { id: 15, name: 'Closure Requested' },
  CLOSED: { id: 16, name: 'Closed' },
  EDITED: { id: 17, name: 'Edited' },
  EDITS_SUBMITTED: { id: 18, name: 'Edits Submitted' },
  PENDING_REVIEW: { id: 19, name: 'Pending Review' },
  REVIEW_COMPLETE: { id: 20, name: 'Review Complete' },
  REVIEWED: { id: 21, name: 'Reviewed' },
  INELIGIBLE: { id: 22, name: 'Ineligible' },
  OFFER_PENDING: { id: 23, name: 'Offer Pending' },
  OFFER_SENT: { id: 24, name: 'Offer Sent' },
  ACCEPTED: { id: 25, name: 'Accepted' },
  APPROVED: { id: 27, name: 'Approved' },
  ERROR: { id: 29, name: 'Error' },
  REMOVED: { id: 30, name: 'Removed' },
  OPEN: { id: 31, name: 'Open' },
  PENDING_APPROVAL: { id: 32, name: 'Pending Approval' },
  SUBSTANTIALLY_APPROVED: { id: 33, name: 'Substantially Approved' },
  RENEWED: { id: 34, name: 'Renewed' },
  NOT_RENEWED: { id: 35, name: 'Not Renewed' },
  UNPUBLISHED: { id: 36, name: 'Unpublished' },
  ACTIVE: { id: 37, name: 'Active' },
  ARCHIVED: { id: 38, name: 'Archived' },
};

export const PAGE_TYPES = {
  DEFAULT: '',
  NAVLESS: 'page--no-sidebar user--logged-out',
  SITE_NAV_COLLAPSED: 'site-nav--collapsed'
};

export const ADMIN_CATEGORY_ID = 48;

export const STATE_CA = 'CA';

export const MAX_ALLOCATION_COLS = 3;

export const NOT_ENTERED = 'Not Entered';

export const OBSCURED_USER_NAME = '**********';

export const DEFAULT_VAL = 'default_val';

export const ARIA_LABELS = {
  EXCLUDED_REVIEW: 'This review has been excluded from the final score because the comprehensive score falls outside of specific scoring parameters.',
  ADDITIONAL_REVIEWER: 'An additional reviewer has been added to this application.',
  SAME_REVIEW_SCORE: 'Two or more applications with the same review score',
  NO_LAUNCHBOARD_DATA: 'No LaunchBoard data.'
}

export const UPLOAD_LIMIT = {
  SHORT: '45MB',
  VALUE: 45000000
};

export const NUM_REGEX = /[^\d\.-]/g;

export const EVENT_TYPES = {
  // Program Events
  ALLOCATION_DEADLINE: 'Allocation Deadline',
  PROGRAM_EVENTS: 'Program Events',
  FUND_SETUP: 'Fund Setup',

  // Project Events
  APPLICATION: 'Application',
  REVIEW: 'Application Review',
  FUNDING: 'Application Funding',
  PROJECT: 'Plan',
  REPORT: 'Fiscal Report',

  // CAEP
  CAEP_ANNUAL_PLAN: 'Annual Plan',
  CAEP_CFAD: 'CFAD',
  CAEP_WORKPLAN: 'Budget & Workplan',
  CAEP_PROGRAM_REPORTS: 'Program Areas & Leveraged Funds Reporting',
  CAEP_WEP: 'Carryover Compliance Written Expenditure Plan',

  // SEP
  SEP_ANNUAL_REPORT: 'Annual Report',
  SEP_ALLOCATION_REPORT: 'Allocation-End Report',

  // Perkins
  PERK_HEADCOUNT: 'Headcount Certification'
};

export const EVENT_NAMES = {
  OPEN_DATE: 'Open Date', // Only used for Applications
  START_DATE: 'Start Date',
  DUE_DATE: 'Due Date',
  CERITIFICATION_DATE: 'Certification Date'
};

export const DATE_TIME_MOMENT_FORMAT_STRINGS = {
  NOVA_timezone: 'America/Los_Angeles',
  NOVA_defaultYear: 1904,
  date: 'MM/DD/YYYY',
  dateYearless: 'MMM Do',
  dateMedium: 'MMM Do, YYYY',
  dateLong: 'MMMM D, YYYY',
  dateTime: 'MM/DD/YYYY HH:mm',
  dateTime12Hours: 'MM/DD/YYYY hh:mm A',
  dateTime12HoursTZ: 'MM/DD/YYYY hh:mm A z',
  dateTime24HoursTZ: 'MM/DD/YYYY HH:mm z',
  dateTimeLong12HoursTZ: 'hh:mm A on MMMM DD, YYYY z',
  monthYear: 'MM/YYYY',
  monthDay: 'MM/DD',
  time12Hrs: 'h:mm A',
  time24Hrs: 'HH:mm',
  isoDate: 'YYYY-MM-DD',
}

export const STATUS_STRINGS = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete',
  NOT_SUBMITTED: 'Not Submitted',
  OPTIONAL: 'Optional',
  SUBMITTED: 'Submitted',
};

export const ALLOCATION_DIRECTIONS = {
  FROM: 'from',
  TO: 'to'
};


export const BROWSER_UA_BLACKLIST = [
  'Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; rv:11.0) like Gecko', // IE11
];

export const REPORTING_CADENCES = [
  {
    label: 'Quarterly',
    value: 'quarterly',
    cadence: 1  // Every 1 quarter
  },
  {
    label: 'Biannually',
    value: 'biannually',
    cadence: 2  // Every 2 quarters
  },
  {
    label: 'Annually',
    value: 'annually',
    cadence: 4  // Every 4 quarters
  },
  {
    label: 'Never',
    value: 'never',
    cadence: 0
  },
];

export const NOVA_SERVICE_USER_AGENT_PREFIX = 'NOVAService';

export const FORECAST_SUM_HELP_TEXT = `Forecast the year-to-date spending by enterting the cumulative percentages for each quarter.
  For example: 15% for Q1, 30% for Q2, 45% for Q3, and 60% for Q4.`

export const AEBG_BUDGET_V1_MAX_YEAR = 2021;

export const DEFAULT_SCORING_INSTRUCTION = 'This section does not require scoring, but you may add notes in the Comments field.';

export const MESSAGE_STATES = {
  UNREAD: 'unread',
  READ: 'read',
  ARCHIVED: 'archived',
  DELETED: 'deleted'
};

export const FISCAL_REPORTING_GUIDANCE = `<p>Fiscal reporting becomes available when plans have entered a certified status. Reporting is
  cumulative across reporting periods - meaning expenditure values will either remain unchanged or increase. Expenditure amounts greater
  than budgeted amounts are acceptable in order to accommodate over-spending.</p><p>To update a report that has already been submitted
  and/or certified, click the “Unsubmit Report” button to put the report in an editable state. To update a prior period, unsubmit all
  subsequent reports in reverse chronological order. Unsubmitted reports will need to be re-submitted.</p>`;